<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.67 109.54">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <polyline
          :style="`stroke: ${color}`"
          style="stroke-width: 7.37"
          class="cls-base"
          points="3.69 105.85 34.98 54.77 3.69 3.69"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Chevron',
  props: {
    color: { type: String, default: 'currentColor' }
  }
}
</script>