<template>
  <div class="bienvenida">
    <div class="bg-black flex justify-center py-4 px-8">
      <div class="w-full flex flex-col items-center justify-center">
        <Logo class="w-36 pb-2" />
        <div class="line-draw"></div>
      </div>
    </div>

    <div class="body">
      <h2 class="text-xl mb-4">LAS REGLAS DEL JUEGO.</h2>
      <div class="list mb-3">
        <div v-for="(texto, t) in textos1" :key="texto" class="list-item">
          <span>{{ t + 1 }}.</span>
          <span class="text"> {{ texto }} </span>
        </div>
      </div>

      <div class="mb-3">
        <div class="dificulty-item">
          <DificultadItem :level="1" />
          <span>FÁCIL/CAMBIOS</span>
        </div>
        <div class="dificulty-item">
          <DificultadItem :level="2" />
          <span>NORMAL</span>
        </div>
        <div class="dificulty-item">
          <DificultadItem :level="3" />
          <span>TE LLEVA TODO EL DÍA</span>
        </div>
        <div class="dificulty-item">
          <DificultadItem :level="4" />
          <span>MÁS DE UN DÍA</span>
        </div>
      </div>

      <div class="list mb-8">
        <div v-for="(texto, t) in textos2" :key="texto" class="list-item">
          <span>{{ t + 10 }}.</span>
          <span class="text"> {{ texto }} </span>
        </div>
      </div>

      <div class="danger bg-black text-white flex flex-col py-3 px-5 rounded-lg mb-8">
        <div class="flex items-end justify-center gap-3 mb-8">
          <div class="w-16">
            <WarningIcon class="w-full" />
          </div>
          <h4 class="text-3xl w-2/3">NO HAY EXCUSAS</h4>
        </div>
        <div class="warning">PRIMER DÍA DE NO INGRESAR TRABAJO: NOTA PRIMER AVISO.</div>
        <div class="warning">SEGUNDO DÍA DE NO INGRESAR TRABAJO: NOTA SEGUNDO AVISO.</div>
        <div class="warning">TERCER DÍA DE NO INGRESAR TRABAJO: NOTA TERCER AVISO.</div>
        <div class="warning">CUARTO DÍA DE NO INGRESAR TRABAJO: DESPIDO JUSTIFICADO.</div>
      </div>

      <div class="flex bg-black rounded-lg p-3 pt-1">
        <input v-model="termsAccepted" type="checkbox" name="terms" class="mt-3 mr-2" />
        <div class="terms-heading text-gradient font-westmount-exrabold cursor-pointer" @click="toggleTerms">
          LEÍ, ENTENDÍ Y ACEPTO TODAS LA REGLAS
        </div>
      </div>
    </div>

    <div class="p-1 pb-5">
      <ArrowButton
        text="continuar"
        disabled-background="#999999"
        :disabled="!termsAccepted"
        @onClick="proceed"
      />
    </div>
  </div>
</template>

<script>
import { isDirector } from "@/utils/roles"
import { mapGetters, mapMutations} from "vuex"
import Logo from '@/components/shared/icons/Logo.vue'
import WarningIcon from '@/components/shared/icons/WarningIcon.vue'
import ArrowButton from '@/components/shared/ArrowButton.vue'
import DificultadItem from '@/components/dificulty/DificultadItem.vue'
export default {
  name: "Bienvenida",
  components: { Logo, WarningIcon, DificultadItem, ArrowButton },
  data() {
    return {
      termsAccepted: false,
      textos1: [
        'TODOS LOS DÍAS TERMINAN AQUÍ.',
        'TODOS.',
        'NO HAY EXCUSAS.',
        `NO ES POR JODER, ES LA ÚNICA MANERA EN LA QUE PODREMOS Y PODRÁS MEDIR TU DESEMPEÑO, 
        EFICIENCIA Y LAS NECESIDADES / OPORTUNIDADES QUE NOS PERMITAN TOMAR ACCIONES 
        COMO AUMENTOS Y CONTRATACIONES.`,
        'POR LO TANTO TE CONVIENE HACERLO, Y HACERLO BIEN.',
        'A CONCIENCIA.',
        `DEBERÁS LLENAR TU FORMULARIO CON CADA TRABAJO QUE HAGAS EN EL DÍA. (LO HAYAS TERMINADO O NO).`,
        `TODOS LOS DÍAS.`,
        `CADA TRABAJO ESTARÁ CALIFICADO COMO:`
      ],
      textos2: [
        `LA FECHA ES AUTOMÁTICA POR LO QUE TE SERÁ IMPOSIBLE INGRESAR TUS TRABAJOS DESPUÉS DE LAS 11:59:59 PM.`,
        `AL INGRESAR TUS TRABAJOS LLEGARÁ A TU CORREO UN MAIL DE CONFIRMACIÓN. SI NO LLEGA, 
        VUELVE A INTENTARLO O SOLICITA AYUDA A ALEXIS@RRGROUPHUB.COM`
      ]
    }
  },

  computed: {
    ...mapGetters({ user: 'user/user' })
  },

  mounted() {
    if (!this.user) {
      return this.$router.push({ name: 'Login' })
    }

    this.setActiveLayout('LoginLayout')
  },

  beforeDestroy() {
    this.setActiveLayout('MainLayout')
  },

  methods: {
    ...mapMutations({ setActiveLayout: 'layout/setActiveLayout' }),

    toggleTerms() {
      this.termsAccepted = !this.termsAccepted
      if (this.termsAccepted) {
        localStorage.setItem('rtd_attmpt_accp_trms', 1)
      } else {
        localStorage.removeItem('rtd_attmpt_accp_trms')
      }
    },

    proceed() {
      localStorage.removeItem('rtd_attmpt_accp_trms')
      localStorage.setItem('rtd-accepted-terms', new Date())
      const routeName = isDirector(this.user.roles) ? 'Director' : 'Recurso'
      this.$router.push({ name: routeName })
    }
  }
}
</script>

<style lang="scss" scoped>
.bienvenida {
  background-color: #cccccc;
}

.line-draw {
  height: 1px;
  width: 100%;
  background: linear-gradient(90deg, #fbdb73, #c99344);
}

.body {
  @apply text-black py-3 px-8;

  font-family: WestmountBold;
  letter-spacing: -3px;
}

.list-item {
  @apply mb-1 grid grid-cols-12;

  .text {
    @apply col-span-11;
  }
}

.dificulty-item {
  @apply flex items-center gap-3 mb-2;
}

.warning {
  @apply text-center mb-5;
  letter-spacing: -2px;
}

.terms-heading {
  font-size: 1.5rem;
  line-height: 1;
}
</style>