<template>
  <div
    class="relative w-16 h-9 flex items-center gap-1 justify-center bg-black rounded-lg cursor-pointer"
    @click="$emit('selected')"
  >
    <Ray />
    <Ray v-if="level >= 2" />
    <Cross v-if="level === 3" />
    <Ray v-if="level === 4" />

    <div v-if="unselected" class="unselected-overlay"></div>
  </div>
</template>

<script>
import Ray from '@/components/dificulty/partials/Ray.vue'
import Cross from '@/components/dificulty/partials/Cross.vue'

export default {
  name: 'DificultadItem',
  components: { Ray, Cross },
  props: {
    level: { type: Number, default: 1 },
    unselected: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.dificulty-icon {
  min-width: 12px;
}

.unselected-overlay {
  @apply absolute top-0 w-full h-full rounded-lg;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>