<template>
  <div>
    <button
      class="w-full flex justify-between items-center px-4 py-2"
      :style="`background-color: ${backgroundColor}; color: ${computedColor}`"
      :disabled="disabled"
      @click="$emit('onClick')"
    >
      <div class="button-text">{{ text.toUpperCase() }}</div>
      <div class="w-10px" :style="`transform: rotate(${rotate})`"><Chevron /></div>
    </button>
  </div>
</template>

<script>
import Chevron from '@/components/shared/icons/Chevron.vue'
export default {
  name: 'ArrowButton',
  components: { Chevron },
  props: {
    text: { type: String, default: 'Button' },
    color: { type: String, default: '#fff' },
    background: { type: String, default: '#000' },
    disabled: { type: Boolean, default: false },
    disabledBackground: { type: String, default: '#000' },
    disabledColor: { type: String, default: '#fff' },
    rotate: { type: String, default: '0' }
  },
  computed: {
    backgroundColor() {
      return this.disabled ? this.disabledBackground : this.background
    },
    computedColor() {
      return this.disabled ? this.disabledColor : this.color
    }
  }
}
</script>

<style scoped>
.w-10px {
  width: 10px;
}

button {
  transition: background-color 0.2s ease-out;
}

.button-text {
  @apply text-lg;
  font-family: ProximaNovaSemiBold;
  letter-spacing: 1px;
}
</style>
