<template>
  <div class="dificulty-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 51.34 51.43"
    >
      <defs>
        <linearGradient
          id="New_Gradient_Swatch_2"
          y1="25.72"
          x2="51.34"
          y2="25.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fcdc74" />
          <stop offset="1" stop-color="#ca9445" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            style="stroke-width: 2.66; stroke: url(#New_Gradient_Swatch_2)"
            class="cls-base"
            points="36.73 36.8 43.59 36.6 50.5 36.58 50.28 29.25 50.51 21.97 50.24 14.68 43.47 14.7 36.78 14.95 36.38 7.99 36.58 0.83 29.17 1.29 21.89 1.15 14.76 1.35 15.04 8.11 15.14 15.21 9.01 14.53 0.83 15.11 0.9 22.32 1.14 29.59 1.14 36.99 8.96 36.28 14.66 37.1 14.79 42.41 15.02 50.6 22.24 50.6 29.51 50.44 36.63 50.03 36.81 42.46 36.73 36.8"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DificultyCross'
}
</script>