<template>
  <div class="dificulty-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 49.54 81.94"
    >
      <defs>
        <linearGradient
          id="New_Gradient_Swatch_2"
          y1="40.97"
          x2="49.54"
          y2="40.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fcdc74" />
          <stop offset="1" stop-color="#ca9445" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            style="stroke-width: 2.66; stroke: url(#New_Gradient_Swatch_2)"
            class="cls-base"
            points="16.36 1.1 24.63 1.22 32.97 0.99 41.6 0.83 37.44 8.58 33.69 16.22 30.37 24.08 39.44 24.05 48.71 23.94 43.75 31.38 38.58 38.38 33.78 45.64 28.58 52.62 23.96 60.01 18.89 67.08 13.62 74.03 8.83 81.11 11.87 72.95 14.11 64.34 16.93 55.93 20.05 47.61 22.92 39.18 15.54 39.46 8.2 39.41 0.83 39.21 3.94 31.57 6.71 23.8 9.77 16.15 12.86 8.51 16.36 1.1"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Ray'
}
</script>